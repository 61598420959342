<template>
  <modal :show="show" @close="close">
    <div
      data-cy="delete-account-modal-header"
      slot="header"
      class="modal-title"
    >
      Are you sure you want to delete your account?
    </div>

    <div>
      <base-alert
        data-cy="delete-account-modal-alert"
        type="danger"
        radius="rounded-lg"
        class="mb-4"
      >
        This action is <strong>IRREVERSIBLE</strong> and your data will be
        <strong>COMPLETELY REMOVED</strong>.
      </base-alert>

      <form role="form" @submit.prevent="deleteAccount" class="needs-validation">
        <div class="space-y-3 mb-2">
          <p>
            Your data will be removed from our database and the partner services we use to provide
            you with a great experience.
          </p>
          <p>
            You will be logged out after confirmation.
          </p>
          <p>
            Confirm you want to delete your account by typing your Twitter username:
            <span class="font-weight-bold">{{ this.userProfile.username }}</span>
          </p>
        </div>

        <div class="space-y-3">
          <input
            data-cy="delete-account-modal-input"
            class="form-control"
            autofocus
            type="text"
            placeholder="Enter username"
            v-model="username"
          />
        </div>

        <div class="grid mt-6 justify-end">
          <base-button
            data-cy="delete-account-modal-delete-button"
            class="button small-button custom-button danger"
            :loading="isDeleting"
            @click="deleteAccount"
            :disabled="username !== userProfile.username"
          >
            <span>Confirm</span>
          </base-button>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import controller from '@/controller';
  import LogoutMixinVue from '../Mixins/LogoutMixin.vue';
  import SwalModalMixinVue from '../Mixins/SwalModalMixin.vue';
  import lodash from 'lodash';

  export default {
    data() {
      return this.initialState();
    },
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        userProfile: 'getUserProfile',
        parentUserProfile: 'getParentUserProfile',
      }),
      isAllowedToDeleteAccount() {
        return lodash.get(this.parentUserProfile, 'uid') === this.userProfile.uid;
      },
    },
    watch: {
      show: function (show) {
        if (!show) {
          return;
        }
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      initialState() {
        return {
          username: '',
          isDeleting: false,
        };
      },
      async deleteAccount() {
        if (!this.isAllowedToDeleteAccount) return;

        this.isDeleting = true;
        try {
          await controller.deleteAccount(this.currentUser, this.userProfile.uid);
          this.$eventStore.deleteAccount();
          this.$emit('close');
          this.$notify({
            type: 'success',
            message: `Your request to delete your account has been successfully submitted. We'll log you out now.`,
          });
          setTimeout(() => this.logout(), 5000);
        } catch (error) {
          this.swalModal({
            title: 'Error',
            html: `An error has occurred while trying to delete your Hypefury account.`,
            type: 'warning',
          });
          this.isDeleting = false;
        }
      },
    },
    name: 'delete-account-modal',
    mixins: [LogoutMixinVue, SwalModalMixinVue],
    props: {
      show: Boolean,
    },
  };
</script>

<style>
  @supports (padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom) !important;
    }
  }
</style>
