<script>
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';

  export default {
    name: 'youtube-mixin',
    data() {
      return {
        youtubeChannels: null,
        isTryingToConnectYouTube: null,
      };
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      isUserConnectedToYouTubeWithChannel() {
        return (
          lodash.get(this.userProfile, 'integrations.google.tokenInfo.accessToken') &&
          lodash.get(this.userProfile, 'integrations.youtube.channel.id')
        );
      },
      isUserConnectedToGoogle() {
        return lodash.get(this.userProfile, 'integrations.google.tokenInfo.accessToken');
      },
      youtubeStatusText() {
        if (this.isUserConnectedToYouTubeWithChannel) {
          return `You are connected to YouTube as ${this.selectedYouTubeChannelName}.`;
        } else {
          return 'Connect your YouTube account to Hypefury.';
        }
      },
      selectedYouTubeChannelId() {
        return lodash.get(this.userProfile, 'integrations.youtube.channel.id', null);
      },
      selectedYouTubeChannelName() {
        return lodash.get(this.userProfile, 'integrations.youtube.channel.name', null);
      },
    },
  };
</script>
