<script>
  import { mapGetters } from 'vuex';
  import dao from '@/dao';
  import getActiveBetaFeatures from '@/util/getActiveBetaFeatures';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import lodash from 'lodash';

  export default {
    name: 'beta-features-mixin',
    methods: {
      getBetaFeatureDescription(featureName) {
        switch (featureName) {
          default:
            return '';
        }
      },
      getBetaFeatureName(featureName) {
        switch (featureName) {
          default:
            return '';
        }
      },
      // Due to Vue limitations https://github.com/vuejs/vue/issues/2164
      getDifference(oldValue, newValue) {
        function changes(object, base) {
          return lodash.transform(object, function (result, value, key) {
            if (!lodash.isEqual(value, base[key])) {
              result[key] =
                lodash.isObject(value) && lodash.isObject(base[key])
                  ? changes(value, base[key])
                  : value;
            }
          });
        }
        return changes(oldValue, newValue);
      },
    },
    watch: {
      computedFeatures: {
        handler(newValue, oldVal) {
          if (!oldValue) return null;
          const userId = this.userProfile.uid;
          const oldValue = this.getDifference(oldVal, newValue);
          const featureName = Object.keys(oldValue)[0];
          const newFeature = this.betaFeatures[featureName];
          const oldFeature = oldValue[featureName];

          try {
            if (!newFeature.enabled && oldFeature.enabled) {
              dao.userProfile.disableBetaFeature(userId, featureName);
            } else if (!oldFeature.enabled && newFeature.enabled) {
              if (!newFeature.canBeDisabled) {
                this.swalModal({
                  title: 'Warning',
                  html: `Once you enable this feature you won't be able to disable it again. Do you want to continue?`,
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes',
                  cancelButtonText: 'No',
                }).then((result) => {
                  if (result.isConfirmed) {
                    dao.userProfile.enableBetaFeature(userId, featureName);
                  } else {
                    this.$set(this.betaFeatures[featureName], 'enabled', false);
                  }
                });
              } else {
                dao.userProfile.enableBetaFeature(userId, featureName);
              }
            }
          } catch (e) {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while toggling this feature.`,
              type: 'warning',
            });
          }
        },
        deep: true,
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      betaFeatures() {
        return lodash.get(this.userProfile, 'settings.betaFeatures', {});
      },
      computedFeatures() {
        return lodash.cloneDeep(this.betaFeatures);
      },
      activeBetaFeatures() {
        return getActiveBetaFeatures(this.userProfile);
      },
    },
    mixins: [SwalModalMixin],
  };
</script>