export default function activeBetaFeatures(userProfile) {
  const betaFeatures = userProfile.settings.betaFeatures;
  const toReturn = [];

  if (betaFeatures) {
    Object.keys(betaFeatures).forEach((featureName) => {
      if (betaFeatures[featureName] && betaFeatures[featureName].enabled) {
        toReturn.push(featureName);
      }
    });
  }

  return toReturn;
}