<template>
  <v-select
    :ref="refProp"
    :data-cy="dataCy"
    :v-model="model"
    :autocomplete="autocomplete"
    :clearable="clearable"
    class="v-select"
    :class="classes"
    @input="onInput"
    :value="model"
    :options="options"
  />
</template>
<script>
  import 'vue-select/dist/vue-select.css';
  export default {
    name: 'vue-select',
    props: {
      dataCy: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      autocomplete: {
        type: String,
        default: 'off',
      },
      model: {
        type: String,
        default: '',
      },
      classes: {
        type: String,
        default: '',
      },
      refProp: {
        type: String,
        default: '',
      },
    },
    methods: {
      onInput(event) {
        this.$emit('input', event);
      },
    },
  };
</script>
<style lang="scss">
  .v-select .vs__search::placeholder,
  .v-select .vs__selected {
    color: var(--color-primary);
  }
  .v-select .vs__dropdown-menu {
    background: var(--color-dark);
  }

  .v-select .vs__dropdown-option {
    color: var(--color-primary);
  }

  .v-select .vs__dropdown-toggle {
    height: 2.5rem;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-color: var(--input-border);
  }

  .v-select .vs__dropdown-option--highlight {
    background: var(--color-accent);
  }
</style>